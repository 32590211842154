import { React, useState } from "react";
import { Dropdown, Table } from "@uitk/react";

import ModalCardEmpty from "../UIKit/ModalCardEmpty";
const recommendation = [
  { id: "1", label: "Full", value: "Full", tablets: 30, refil: 10 },
  { id: "2", label: "Bridge", value: "Bridge", tablets: 30, refil: 2 },
  { id: "3", label: "Bridge Appt", value: "BridgeAppt", tablets: 30, refil: 2 },
  { id: "4", label: "No Action", value: "NotAct", tablets: 0, refil: 0 },
];

function DashboardScreen({ dataSource, setDataSource }) {
  const [open, setOpen] = useState(false);
  const [dataID, setDataID] = useState(-1);
  const handleChange = (event, id, prescriptionNo) => {
    const { id: dropdownId } = event;
    
  
    const newData = dataSource.map((item) => {
      const selectedPrescription = {
       pNo : null,
       isNoAction : false
      }
      if (item.id === id) {
        const prescriptionList = item.prescriptionList.map((val) => {
          if (val.prescriptionNo === prescriptionNo) {
              
              selectedPrescription.pNo =prescriptionNo
              selectedPrescription.isNoAction = dropdownId === "4"

           // if (dropdownId === "4") {
              // if(selectedPrescription[id]) {
              //   selectedPrescription[id][prescriptionNo] = { noActionSelected : dropdownId === "4"}
              // } else {
              //   selectedPrescription[id][prescriptionNo] = { noActionSelected : dropdownId === "4"}
              // }
           // }
            
            return { ...val, dropdownId };
          } else return val;
        });
        let newObj = {}
        if(item.selectedPrescription) {
          newObj = Object.assign(item.selectedPrescription)  // {1: true}
          newObj[selectedPrescription.pNo] = selectedPrescription.isNoAction
        } else {
          newObj[selectedPrescription.pNo] = selectedPrescription.isNoAction
        }
        return {
          ...item,
          prescriptionList: prescriptionList,
          
          selectedPrescription: newObj
        };
      } else return item;
    });
    setDataSource(newData);
  };

  const onRenderRecommendationCell = ({
    dropdownId,
    patientId,
    prescriptionNo,
  }) => {
    const value = recommendation.filter((item) => item.id === dropdownId)?.[0];
    return (
      <Dropdown
        type="single"
        items={recommendation}
        value={value}
        onChange={(event) => {
          handleChange(event, patientId, prescriptionNo);
        }}
      />
    );
  };
  const viewDetails = (id) => {
    setDataID(id);
    setOpen(true);
  };
  const onRenderViewDetailsCell = ({ patientId }) => {
    return (
      <button
        style={{
          fontSize: "11px",
          background: "none",
          border: "none",
          color: "blue",
        }}
        onClick={() => viewDetails(patientId)}
      >
        View Details
      </button>
    );
  };
  const renderDesription = ({ prescription, dropdownId }) => {
    const value = recommendation.filter((item) => item.id === dropdownId)?.[0];
    const prescriptionValue = prescription
      .replace("<TABLETS>", value?.tablets)
      .replace("<REFIL>", value?.refil);
    return (
      <div
        style={{
          maxWidth: "400px",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {prescriptionValue}
      </div>
    );
  };

  const config = {
    columns: [
      { label: "Recommendation", onRenderCell: onRenderRecommendationCell },
      { label: "Patient", key: "patientName" },
      {
        label: "Prescription",
        key: "prescription",
        onRenderCell: renderDesription,
      },
      { label: "Last PCP Visit", key: "lastpcpvisit" },
      { label: "Protocol", onRenderCell: onRenderViewDetailsCell },
    ],
  };
  const myData = [];
  dataSource?.forEach((item) => {
    if (item.prescriptionList && item.prescriptionList.length > 0) {
      item.prescriptionList.forEach((data, index) => {
        if (data.outcome == "PASS") {
          myData.push({
            ...data,
            patientName: item.patientName,
            id: `id_${item.patientName}_${index}`,
            patientId: item.id,
          });
        }
      });
    }
  });

  return (
    <>
      <div style={{ marginBottom: "10px" }}>Eprescriber Optum, MD </div>
      <div>
        <Table data={myData} config={config} />
        {open && (
          <ModalCardEmpty
            setOpen={setOpen}
            dataID={dataID}
            dataSource={dataSource}
          />
        )}
      </div>
    </>
  );
}

export default DashboardScreen;
