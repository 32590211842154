import React from "react";
import { Card, Heading } from "@uitk/react";



import "./ModalCard.css";

import RenderIcon from '../Components/RenderIcons'

const ModalCardEmpty=({setOpen,dataID, dataSource})=>{

    const details = dataSource?.filter(item => item.id === dataID)?.[0]?.viewProtocalDetail

    return (
      <Card
      className="cardModel"
     
    >
     
      {
        details.map((item)=>{

          return(
            <div> { RenderIcon(item.icon) } {item.value} </div>
          )
        })
      }

   
      <div>
        {" "}
        <button className="button-corner" onClick={() => setOpen(false)}>X</button>
      </div>
    </Card>
    );
}
export default ModalCardEmpty