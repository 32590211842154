import React from "react";

const UserMessage = ({ content }) => {
  return (
    <div class="chat-container">

      <div class="chat-message user">
     
        {content}
      </div>
    </div>
  );
};

export default UserMessage;
