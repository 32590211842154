const middlePanelData = [
  {
    id: "1",
    patientName: "One, Patientone",
    sender: "MyKelseyOnline.",
    datetime: "",
    orderID: "1",
    comment: "comment",
    inbox: "Inbox",
    lastAccessed: "",
    encProvider: "E-prescriber Optum MD",
    pool: "pool",
    open: "open",
    recommendation: "noAction",
    prescriptionList: [
      {
        prescription:
          "Atenolol 25 MG oral Tablet. Disp <TABLETS> tablets, refills <REFIL>. Take 1 tablet (25 mg total) by mouth daily. KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "6/3/2023; Eprescriber Optum, MD",
        dropdownId: "1",
        prescriptionNo: "1",
        outcome:"PASS"
      },
    ],

    medicationRenewalRequest: {
      detail:
        "Atenolol 25 MG oral tablet  (Eprescriber Optum, MD)Patient Comment. Please Refill this medication Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX",
    },
    requestRenewals: [
      {
        prescriptionNo: "1",
        pass: true,
        header: "Atenolol 25 MG oral Tablet",
        detail1: "Sig: take 1 tablet (25 mg total) by mouth daily ",
        detail2: " Disp: 30 tablet  Refills: 6",
        detail3: "Atenolol 25 MG oral Tablet",
        detail4: "Start: 4/23/2024 ; Class: E-Prescribe ; Non-formulary",
        comments:
          "Last ordered: 6 months (10/25/2023) by Eprescriber Optum, MD",
        betaHeader: "Beta Blockers Protocol Passed",
        beta1: "Normal serum creatinine on file in past 12 months",
        beta2: " Normal serum potassium on file in past 12 months",
        beta3: "Recent or future visit with provider/department",
        footer: "To be filled at: KELSEY PHARMACY – testing – Houston, TX",
        betaList: [
          {
            text: "Normal serum creatinine on file in the past 12 months",
            icon: "Tick",
          },
          {
            text: "Normal serum potassium on file in the past 12 months",
            icon: "Tick",
          },
          {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          {
            text: "BP under 140/90 in past year or if patient has diabetes,CAD,or PVD;BP under 130/80 in past year",
            icon: "Tick",
          },
        ],
        protocalDetail: {
          tableHeader1: "AA",
          tableHeader2: "BB",
          tableHeader3: "CC",
          tableStatus: true,
          status: false,
          header: "Hmg CoA Reductase Protocol Failed ",
          title: " Recent or future visit with provider/department  ",
          list: "Last Encounter This Provider: 1/14/2023",
          creatinine: {
            date: "01/01/2024",
            value: "1",
            range: "1",
            status: "Final",
          },
          lipid: {
            date: "01/02/2024",
            value: "2",
            range: "1",
            status: "Final",
          },
          ast: { date: "01/02/2024", value: "3", range: "1", status: "Final" },
        },
      },
    ],

    dataReceived: {
      status: true,
      title:
        " 4/22/2024 - RX REQUEST: Mykelseyonline, Account and Epresciber Optum, MD",
      pendedOrder: "MyKelseyonline,Account pended order",
      datereceived: "04/22/2024",
      msg: "Refills have been requested fro the following medications : Atenolol 25 MG oral Tablet (E-prescriber Optum MD) Preferred Pharmacy – KELSEY PHARMACY – MAIN –HOUSTON, TX – 27/27 W HOLCOME B1",
      heading: "",
      popup: true,
    },
    recommendation: {
      status: false,
      title: "",
      heading: "",
      detail1: "",
      detail2: "",
      detail3: "",
      egfr: { date: "01/01/2024", value: "1", range: "1", status: "Final" },
      ast: { date: "01/02/2024", value: "2", range: "1", status: "Final" },
      clearance: {
        date: "01/02/2024",
        value: "3",
        range: "1",
        status: "Final",
      },
      egfrStatus: false,
      astStatus: false,
      clearanceStatus: false,
    },
    viewProtocalDetail: [
      {
        value: "Patient is not pregnant nor breastfeeding​",
        icon: "Tick",
      },
      {
        value: "Medication on active med list ​",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP​",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP​",
        icon: "Tick",
      },
      {
        value: "Minimum of 30 days ​",
        icon: "Tick",
      },
      {
        value: "No DUR identified ​",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in last 12 months ",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in next 90 days ​",
        icon: "Cross",
      },
      {
        value: "Minimum of 30 days ​",
        icon: "Tick",
      },
      {
        value:
          "Previous order was not a bridge refill per the automated solution​",
        icon: "Tick",
      },
      {
        value: "Blood Pressure at goal within last 12 months​",
        icon: "Tick",
      },
      {
        value: "Same medication and dose ≥ 3 months​",
        icon: "Tick",
      },
      {
        value: "HR ≥ 55 bpm within last 12 months​",
        icon: "Tick",
      },
      {
        value: "K+ / Na+ checked in last 12 months",
        icon: "Tick",
      },
      {
        value: "K+ / Na+ within normal limits",
        icon: "Tick",
      },
    ],
  },
  {
    id: "2",
    patientName: "Two, Patienttwo",
    sender: "SureScripts Int.",
    datetime: "",
    orderID: "1",
    comment: "comment",
    inbox: "Inbox",
    lastAccessed: "",
    encProvider: "E-prescriber OPTUM , MD",
    pool: "pool",
    open: "open",
    recommendation: "noAction",
    prescriptionList: [
      {
        prescription:
          "Atorvastatin 40MG tablet. Disp <TABLETS> tablets, refills <REFIL>. Take 1 tablet (40 mg total) by mouth in the morning .KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "1/14/2023; Eprescriber Optum, MD",
        dropdownId: "3",
        prescriptionNo: "1",
        outcome:"PASS"
      },
      {
        prescription:
          "glyburide 5 MG oral Tablet​ Sig: take 1 tablet (5 mg total) by mouth daily (with breakfast).​ Disp: 30 tablet  Refills: 5​",
        lastpcpvisit: "1/14/2023; Eprescriber Optum, MD",
        // dropdownId: "4",
        prescriptionNo: "2",
        // protocol: "Diabetes",
        outcome: "FAIL",
      },
      {
        prescription:
          "Aformoterol Tartrate (Brovana) 15MCG/2ML inhalation Inhalant. Disp 60 mL, <REFIL> refills.KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "1/14/2023; Eprescriber Optum, MD",
        dropdownId: "3",
        prescriptionNo: "3",
        outcome:"PASS"
      },
    ],
    medicationRenewalRequest: {
      detail:
        "Atorvastatin 40 MG oral tablet  (Eprescriber Optum, MD)glyburide 5 MG oral TabletAformoterol Tartrate (Brovana) 15MCG/2ML inhalation Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX",
    },
    requestRenewals: [
      {
        prescriptionNo: "1",
        header: "Atrovastatin 40 MG oral Tablet",
        detail1: "Sig: take 1 tablet (40 mg total) by mouth in the morning ",
        detail2: " Disp:90 tablet  Refills: 4",
        // detail3: "Atenolol 25 MG oral Tablet",
        detail4: "Start: 4/24/2024",
        detail5: "Class: Normal",
        detail6: "Non-formulary",
        detail7:
          "Last ordered: 11 months (05/23/2023) by Eprescriber Optum, MD",
        pass: true,
        betaHeader: "Hmg CoA Reductase Protocol Failed",
        beta1: "Recent or future visit with provider/department",
        beta2: " Normal creatinine kinase in past 12 months",
        beta3: "Lipid panel in past 12 months",
        beta4: "Normal AST or ALT on file in past 12 months",
        betaList: [
          {
            text: "Recent or future visit with provider/department",
            icon: "Cross",
          },
          { text: "Normal creatinine kinase in past 12 months", icon: "Tick" },
          { text: " Lipid panel in past 12 months", icon: "Tick" },
          {
            text: " Normal AST or ALT on file in past 12 months",
            icon: "Tick",
          },
        ],
        protocalDetail: {
          // tableHeader1:"AA",
          // tableHeader2:"BB",
          // tableHeader3:"CC",
          tableStatus: true,
          status: true,
          header: "Hmg CoA Reductase Protocol Failed ",
          title: {
            text: "Recent or future visit with provider/department  ",
            icon: "Tick",
          },
          list: "Last Encounter This Provider: 1/14/2023 Last Encounter",
          listDetails: [
            "Last Encounter This Provider: 1/14/2023 Last Encounter",
            "Last Encounter This Department: 1/14/2023",
            "Next Encounter This Provider: visit not found",
            "Next Encounter This Department: visit not found",
          ],
          tableHeader1: {
            text: "Normal creatinine kinase in past 12 months",
            icon: "Tick",
          },
          tableHeader2: { text: "Lipid panel in past 12 months", icon: "Tick" },
          tableHeader3: {
            text: "AST less than 55 or ALT less than 90 or not on file in the past 12 months",
            icon: "Tick",
          },
          creatinine: {
            date: "11/5/2023",
            value: "0.7",
            range: "0.56-1.00 mg/dL",
            status: "Final",
          },
          lipid: {
            date: "11/05/2023",
            value: "110",
            range: "0-119 mg/dL",
            status: "Final",
          },
          ast: {
            date: "11/05/2023",
            value: "33",
            range: "<55",
            status: "Final",
          },
        },
      },
      {
        header: "glyburide 5 MG oral tablet",
        detail1:
          "Sig: take 1 tablet (5 mg total) by mouth daily(with breakfast) ",
        detail2: " Disp: 30 tablet  Refills: 11",
        detail3: "Atenolol 25 MG oral Tablet",
        detail4: "Start: 4/24/2024; Normal ; Non-formulary",
        comments:
          "Last ordered: 5 months (11/25/2023) by Eprescriber Optum, MD",
        pass: false,
        betaHeader: "Antihyperglycemic Protocol failed",
        beta1: "Normal HgA1c on file in past 12 months",
        beta2: "Recent or future visit with provider/department",
        beta3: "Normal creatinine serum in past 12 months",
        footer: "Lipid panel in 12 months",
        betaList: [
          { text: "Normal HgA1c on file in past 12 months", icon: "Cross" },
          {
            text: "Recent or future visit with provider/department",
            icon: "Cross",
          },
          { text: "Normal creatinine serum in past 12 months", icon: "Tick" },
          { text: "Lipid panel in past 12 months", icon: "Tick" },
        ],
        protocalDetail: {
          tableStatus: true,
          tableHeader1: {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          tableHeader2: {
            text: "Normal HgA1c less than 7 in past 12 months",
            icon: "Tick",
          },
          tableHeader3: {
            text: "Normal creatinine serum in past 12 months",
            icon: "Tick",
          },
          status: true,
          header: "Antihyperglycemic Protocol Failed ",
          title: {
            text: " Recent or future visit with provider/department  ",
            icon: "Cross",
          },
          list: "Last Encounter This Provider: 1/14/2023",
          list: "Last Encounter This department: 1/14/2023",
          list: "Next Encounter This Provider: visit not found",
          list: "Next Encounter This Department: visit not found",
          listDetails: [
            "Last Encounter This Provider: 1/14/2023",
            "Last Encounter This Department: 1/14/2023",
            "Next Encounter This Provider: visit not found",
            "Next Encounter This Department: visit not found",
          ],
          creatinine: {
            date: "11/5/2023",
            value: "8.7%",
            range: "4.8-5.6%",
            status: "Final",
          },
          lipid: {
            date: "11/05/2023",
            value: "0.7",
            range: "0.56-1.00mg/dL",
            status: "Final",
          },
          ast: { date: "11/05/2023", value: "", range: "", status: "" },
        },
      },
      {
        prescriptionNo: "3",
        header: "Aformoterol Tartrate (Brovana) 15 MCG/2ML Inhalent Solution",
        detail1: "Sig: inhale 2ml(15 mcg total) 2 times daily ",
        detail2: " Disp: 60mL Refills: 11",
        detail3: "Start: 4/24/2024",
        detail4: "Class: Normal ; Non-formulary",
        comments:
          "Last ordered: 11 months (5/25/2023) by Eprescriber Optum, MD",
        pass: true,
        betaHeader: "Nebulizer Solutions for Asthma Protocol failed",
        //beta1: "Normal serum creatinine on file in past 12 months",
        //beta2: " Normal serum potassium on file in past 12 months",
        beta3: "Recent or future visit with provider/department",
        footer: "To be filled at: KELSEY PHARMACY – testing – Houston, TX",
        betaList: [
          {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
        ],
        protocalDetail: {
          tableHeader1: {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          tableHeader2: {
            text: "Normal creatinine kinase in past 12 months",
            icon: "Tick",
          },
          tableHeader3: { text: "Lipid panel in past 12 months", icon: "Tick" },
          tableStatus: true,
          status: true,
          header: "Nebulizer Solutions for Asthma Protocol Failed     ",
          title: {
            text: "Recent or future visit with provider/department ",
            icon: "Cross",
          },
          list: "Last Encounter This Provider: 1/14/2023",
          list: "Last Encounter This Department: 1/14/2023",
          list: "Next Encounter This Provider: visit not found",
          list: " Next Encounter This Department: visit not found",
          listDetails: [
            "Last Encounter This Provider: 1/14/2023",
            "Last Encounter This Department: 1/14/2023",
            "Next Encounter This Provider: visit not found",
            "Next Encounter This Department: visit not found",
          ],
          creatinine: {
            date: "11/5/2023",
            value: "0.7",
            range: "0.56-1.00 mg/dL",
            status: "Final",
          },
          lipid: {
            date: "11/05/2023",
            value: "110",
            range: "0-119 mg/dL",
            status: "Final",
          },
          ast: {
            date: "11/05/2023",
            value: "33",
            range: "<55",
            status: "Final",
          },
        },
      },
    ],

    dataReceived: {
      status: false,
      title:
        " 4/22/2024 - RX REQUEST: SureScripts Interface and Epresciber Optum, MD",
      pendedOrder: "pended 3 orders",
      datereceived: "04/22/2024",
      msg: "Med name dose route (doctor)",
      heading: "",
      popup: false,
    },
    recommendation: {
      status: true,
      title: "Inbox Assistant Recommendation",
      heading: "Diabetes Protocol",
      details: [
        "Refill too soon or less than 90 day fill with 0 refills",
        "Potential diagnosis of heart failure",
        "One of more of the following labs",
      ],
      egfrStatus: true,
      astStatus: true,
      clearanceStatus: true,
      egfr: { date: "07/29/2022", value: "83", range: "", status: "Final" },
      ast: { date: "07/29/2022", value: "40", range: "", status: "Final" },
      clearance: {
        date: "07/29/2022",
        value: "122",
        range: "",
        status: "Final",
      },
    },
    viewProtocalDetail: [
      {
        value: "Patient is not pregnant nor breastfeeding​",
        icon: "Tick",
      },
      {
        value: "Medication on active med list ",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP",
        icon: "Tick",
      },
      {
        value: "Request made ≤ 30 days from expiration",
        icon: "Tick",
      },
      {
        value: "Minimum of 30 days​",
        icon: "Tick",
      },
      {
        value: "No DUR identified",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in last 12 months",
        icon: "Cross",
      },
      {
        value: "Appointment with PCP in next 90 days",
        icon: "Cross",
      },
      {
        value:
          "Previous order was not a bridge refill per the automated solution",
        icon: "Tick",
      },
      {
        value: "Lipid panel in last 12 months",
        icon: "Tick",
      },
      {
        value: "Lipid panel in last 12 months",
        icon: "Tick",
      },
    ],
  },
  {
    id: "3",
    patientName: "Three, Patientthree",
    sender: "Nurse.",
    datetime: "",
    orderID: "1",
    comment: "comment",
    inbox: "Inbox",
    lastAccessed: "",
    encProvider: "E-prescriber Optum,MD",
    pool: "pool",
    open: "open",
    recommendation: "noAction",
    prescriptionList: [
      {
        prescription:
          "Liraglutide (Victoza) 18 MG/3ML subcutaneous Solution Pen-Injector. 18 mg/3 mL, 0 refills​ KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "12/29/2022; Eprescriber Optum, MD",
        dropdownId: "2",
        prescriptionNo: "1",
        outcome:"PASS"
      },
      {
        prescription:
          "Atrovent HFA 17 MCG/ACT IN AERS. Disp 12.9g, 2 refills. Inhale 2 puffs into the lungs.KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "12/29/2022; Eprescriber Optum, MD",
        dropdownId: "2",
        prescriptionNo: "2",
        outcome:"PASS"
      },
    ],
    medicationRenewalRequest: {
      detail:
        "Atorvastatin 40 MG oral tablet  (Eprescriber Optum, MD) glyburide 5 MG oral TabletAformoterol Tartrate (Brovana) 15MCG/2ML inhalation Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX",
    },
    requestRenewals: [
      {
        prescriptionNo: "1",
        header:
          "Liraglutide (Victoza) 18 MG/3ML subcutaneous Solution Pen-Injector",
        detail1: "Sig: Inject 0.6 mg into the skin daily ",
        detail2: " Disp: 18 mg/3 mL  Refills: 5",
        detail3: "Start: 4/26/2024 date",
        detail4: "Class:Normal",
        detail5: "Non-formulary",
        comments: "Last ordered: 3 months (1/24/2024) by Eprescriber Optum, MD",
        pass: true,
        betaHeader: "Antihyperglycemic Protocol Passed",
        beta1: "Normal HgA1c on file in past 12 months",
        beta2: " Normal creatinine serum in past 12 months",
        beta3:
          "Recent or future visit with provider/department or future 3 months Lipid panel in past 12 months",
        betaList: [
          { text: "Lipid panel in past 12 months", icon: "Tick" },
          { text: "Normal HgA1c on file in past 12 months", icon: "Tick" },
          { text: "Normal creatinine serum in past 12 months", icon: "Tick" },
          {
            text: "Recent or future visit with provider/department or future 3 months",
            icon: "Tick",
          },
        ],
        footer: " Lipid panel in past 12 months",
        protocalDetail: {
          tableHeader1: {
            text: "Normal HgA1c less than 7 in past 12 months ",
            icon: "Tick",
          },
          tableHeader2: { text: "Lipid panel in past 12 months", icon: "Tick" },
          tableHeader3: {
            text: "Normal creatinine serum in past 12 months",
            icon: "Tick",
          },
          tableStatus: true,
          status: true,
          header: "Antihyperglycemic Protocol Passed ",
          title: {
            text: "Recent or future visit with provider/department  ",
            icon: "Tick",
          },
          list: "Last Encounter This Provider: 1/14/2023",
          creatinine: {
            date: "08/5/2023",
            value: "6.3%",
            range: "4.8-5.6%",
            status: "Final",
          },
          lipid: { date: "08/05/2023", value: "", range: "", status: "" },
          ast: {
            date: "08/05/2023",
            value: "",
            range: "0.56-1.00 mg/dL",
            status: "Final",
          },
          listDetails: [
            "Last Encounter This Provider: 12/29/2022",
            "Last Encounter This Department: 12/29/2022",
            "Next Encounter This Provider: 5/25/2024",
            "Next Encounter This Department: 5/25/2024",
          ],
        },
      },
      {
        prescriptionNo: "2",
        header: "Atrovent HFA 17 MCG/ACT IN AERS",
        detail1: "Sig: Inhale 2 puffs into the lungs every 6 (six) hours",
        detail2: " Disp: 12.9g  Refills: 11",
        detail3: "Start: 4/23/2024",
        detail4: "Class: Normal",
        detail5: "Non-formulary",
        detail7: "Last ordered: 5 months (11/25/2023) by Eprescriber Optum, MD",
        comments: "Preferred pharmacy: KELSEY PHARMACY – testing",
        pass: true,
        betaHeader: "Nebulizer Solutions for Asthma Protocol Passed",
        beta1:
          "Recent or future visit with provider/department or future 3 months",
        //beta2: " Normal serum potassium on file in past 12 months",
        // beta3: "Recent or future visit with provider/department",
        betaList: [
          {
            text: "Recent or future visit with provider/department or future 3 months",
            icon: "Tick",
          },
        ],
        footer: "To be filled at: KELSEY PHARMACY – testing – Houston, TX",
        protocalDetail: {
          // tableHeader1:"AA",
          // tableHeader2:"BB",
          // tableHeader3:"CC",
          tableStatus: false,
          status: true,
          header: "Nebulizer Solutions for Asthma Protocol Passed",
          title: {
            text: "Recent or future visit with provider/department  ",
            icon: "Tick",
          },
          //list: "Last Encounter This Provider: 12/29/2022",
          //list: "Last Encounter This Department: 12/29/2022 ",
          //list: "Next Encounter This Provider: 5/25/2024",
          //list:"Next Encounter This Department: 5/25/2024",
          listDetails: [
            "Last Encounter This Provider: 1/14/2023 Last Encounter",
            " Recent or future visit with provider/department  ",
            "Hmg CoA Reductase Protocol Failed ",
          ],
          creatinine: {
            date: "11/5/2023",
            value: "0.7",
            range: "0.56-1.00 mg/dL",
            status: "Final",
          },
          lipid: {
            date: "01/02/2024",
            value: "2",
            range: "1",
            status: "Final",
          },
          ast: { date: "01/02/2024", value: "3", range: "1", status: "Final" },
        },
      },
    ],
    dataReceived: {
      status: true,
      title:
        " 4/22/2024 - RX REQUEST: Mykelseyonline, Account and Epresciber Optum, MD",
      pendedOrder: "2",
      datereceived: "01/01/2024",
      msg: "Med name dose route (doctor)",
      heading: "",
      popup: false,
    },
    recommendation: {
      status: false,
      title: "",
      heading: "",
      detail1: "",
      detail2: "",
      detail3: "",
      egfr: { date: "", value: "", range: "", status: "" },
      ast: { date: "", value: "", range: "", status: "" },
      clearance: { date: "", value: "", range: "", status: "" },
      egfrStatus: false,
      astStatus: false,
      clearanceStatus: false,
    },
    viewProtocalDetail: [
      {
        value: "Medication on active med list ​",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP ",
        icon: "Tick",
      },
      {
        value: "Request made ≤ 30 days from expiration",
        icon: "Tick",
      },
      {
        value: "Minimum of 30 days ",
        icon: "Tick",
      },
      {
        value: "No DUR identified",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in last 12 months",
        icon: "Cross",
      },
      {
        value: "Appointment with PCP in next 90 days",
        icon: "Cross",
      },
      {
        value:
          "Previous order was not a bridge refill per the automated solution",
        icon: "Tick",
      },
      {
        value: "Patient <65 years old",
        icon: "Tick",
      },
      {
        value: "H1bA1c within last 9 months",
        icon: "Tick",
      },
      {
        value: "HbA1c within 5.7% - 8.5%",
        icon: "Cross",
      },
    ],
  },
  {
    id: "4",
    patientName: "Four, Patientfour",
    sender: "MyKelseyOnline.",
    datetime: "",
    orderID: "1",
    comment: "comment",
    inbox: "Inbox",
    lastAccessed: "",
    encProvider: "E-prescriber Optum MD",
    pool: "pool",
    open: "open",
    recommendation: "noAction",
    prescriptionList: [
      {
        prescription:
          "Lisinopril 5 MG Tablet Sig: Take 1 tablet (5 mg total) by mouth daily Disp: 30 tablet   Refills: 11",
       // dropdownId: "4",
        prescriptionNo: "1",
        outcome:"FAIL"
      },
     
    ],
    medicationRenewalRequest: {
      detail:
        " Lisinopril 5 MG oral tablet  (Eprescriber Optum, MD)Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX",
    },
    requestRenewals: [
      {
        pass: false,
        header: "Lisinopril 5 MG Tablet",
        detail1: "Sig: take 1 tablet (5 mg total) by mouth daily ",
        detail2: " Disp: 30 tablet  Refills: 11",
        detail3: "Start: 4/24/2024 date Class: Normal Non-formulary",
        comments:
          "Last ordered: 7 days ago (4/16/2024 date) by Eprescriber Optum, MD",
        betaHeader: "ACE Inhibitor Protocol Failed",
        beta1: "Normal serum creatinine on file in past 12 months",
        beta2: " Recent or future visit with provider/department",
        beta3: " Normal creatinine serum in past 12 months",
        footer: "To be filled at: KELSEY PHARMACY – testing – Houston, TX",
        betaList: [
          {
            text: "Normal serum potassium in in past 12 months",
            icon: "Cross",
          },
          {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          { text: "Normal creatinine serum in past 12 months", icon: "Tick" },
          { text: "Blood pressure on record", icon: "Tick" },
        ],
        protocalDetail: {
          tableHeader1: {
            text: "Normal Serum Potassium in past 12 months",
            icon: "Cross",
          },
          tableHeader2: {
            text: "Normal Serum Creatine in past 12 months",
            icon: "Cross",
          },
          tableHeader3: {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          tableStatus: true,
          status: true,
          header: "ACE Inhibitor Protocol Failed",
          title: {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          list: "Last Encounter This Provider: 1/14/2023",
          creatinine: {
            date: "02/07/2024",
            value: "5.3",
            range: "3.7 – 5.1 mmol/L",
            status: "Final",
          },
          lipid: {
            date: "2/7/2024",
            value: "1.3",
            range: "0.56-1.00 mg/dL",
            status: "Final",
          },
          ast: {
            date: "02/07/2024",
            bpreadings: "115/75",
            range: "",
            status: "",
          },
          listDetails: [
            "Last Encounter This Provider: 2/7/2024",
            "Last Encounter This Department: 2/7/2024",
            "Next Encounter This Provider: visit not found",
            "Next Encounter This Department: visit not found",
          ],
        },
      },
    ],
    dataReceived: {
      status: true,
      title:
        " 4/23/2024 - RX REQUEST: Mykelseyonline, Account and Epresciber Optum, MD",
      pendedOrder: "Mykelseyonline,Account pended an order",
      datereceived: "04/23/2024",
      msg: "Refills have been requested for the following medications:Lisinopril 5MG Tablet (Eprescriber Optum MD) Preferred Pharmacy – KELSEY PHARMACY – testing Medication renewals requested in this message routed separately",
      heading: "",
      popup: true,
    },
    recommendation: {
      status: true,
      title: "Inbox Assistant recommendation",
      heading: "Hypertension Protocol",
      details: [
        "Refill too soon or less than 30 days ; No history of asthma or COPD",
      ],
      egfrStatus: false,
      astStatus: false,
      clearanceStatus: false,
      egfr: { date: "07/07/2024", value: "1", range: "", status: "Final" },
      ast: { date: "07/07/2024", value: "1", range: "", status: "Final" },
      clearance: { date: "07/07/2024", value: "2", range: "", status: "Final" },
    },
    viewProtocalDetail: [
      {
        value: "Patient not pregnant nor breastfeeding",
        icon: "Tick",
      },
      {
        value: "Medication on active med list ",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP",
        icon: "Tick",
      },
      {
        value: "Request made ≤ 30 days from expiration",
        icon: "Tick",
      },
      {
        value: "Minimum of 30 days​",
        icon: "Tick",
      },
      {
        value: "No DUR identified",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in last 12 months",
        icon: "Cross",
      },
      {
        value: "Appointment with PCP in next 90 days",
        icon: "Tick",
      },
      {
        value:
          "Previous order was not a bridge refill per the automated solution",
        icon: "Tick",
      },
      {
        value: "Patient < 65 years old",
        icon: "Tick",
      },
      {
        value: "HbA1c within last 9 months and within 5.7% - 8.5%",
        icon: "Tick",
      },
      {
        value: "No diagnosis of heart failure",
        icon: "Tick",
      },
      {
        value: "CrCl within last 12 months and > 45ml/min",
        icon: "Tick",
      },
    ],
  },
  {
    id: "5",
    patientName: "Five, Patientfive",
    sender: "SureScripts Int.",
    datetime: "",
    orderID: "1",
    comment: "comment",
    inbox: "Inbox",
    lastAccessed: "",
    encProvider: "E-prescriber Optum MD",
    pool: "pool",
    open: "open",
    recommendation: "noAction",
    prescriptionList: [
      {
        prescription:
          "Rosuvastatin Calcium 5 MG oral Tablet. Disp 30 tablets, 10 refills. Take 1 tablet (5 MG total).KELSEY PHARMACY – testing – Houston, TX",
        lastpcpvisit: "1/14/2023; Eprescriber Optum, MD",
        dropdownId: "1",
        prescriptionNo: "1",
        outcome:"PASS"
      },
    ],
    medicationRenewalRequest: {
      detail:
        " Rosuvastatin Calcium 5 MG oral tablet  (Eprescriber Optum, MD) Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX",
    },
    requestRenewals: [
      {
        prescriptionNo: "1",
        pass: true,
        header: "Rosuvastatin Calcium 5 MG oral Tablet",
        detail1: "Sig: take 1 tablet (5 mg total) by mouth daily ",
        detail2: " Disp: 30 tablet  Refills: 11",
        detail3: "Start: 4/27/2024",
        detail5: "Class: Normal",
        detail6: "Non-formulary",
        comments:
          "Last ordered: 11 months ago (5/20/2023)by Eprescriber Optum, MD",
        betaHeader: "Hmg CoA Reductase Inhibitor Protocol Passed",
        beta1: " Lipid panel in past 12 months",
        beta2: "  Recent or future visit with provider/department",
        beta3:
          " Normal AST or ALT on file in past 12 months Normal creatinine kinase in past 12 months ",
        footer: "To be filled at: KELSEY PHARMACY – testing – Houston, TX",
        betaList: [
          { text: "Lipid panel in past 12 months", icon: "Tick" },
          {
            text: "Recent or future visit with provider/department",
            icon: "Tick",
          },
          { text: "Normal AST or ALT on file in past 12 months", icon: "Tick" },
          { text: "Normal creatinine kinase in past 12 months", icon: "Tick" },
        ],
        protocalDetail: {
          tableHeader1: {
            text: " Normal creatinine kinase in past 12 months",
            icon: "Cross",
          },
          tableHeader2: {
            text: "Lipid panel in past 12 months",
            icon: "Cross",
          },
          tableHeader3: {
            text: "AST less than 55 or ALT less than 90 or not on file in the past 12 months",
            icon: "Cross",
          },
          tableStatus: true,
          status: true,
          header: "Hmg CoA Reductase Protocol Passed ",
          title: {
            text: "Recent or future visit with provider/department ",
            icon: "Cross",
          },
          list: "Last Encounter This Provider: 1/14/2023",

          creatinine: { date: "", value: "", range: "", status: "" },
          lipid: {
            date: "06/20/2023",
            value: "110",
            range: "0-119 mg/dL",
            status: "Final",
          },
          ast: {
            date: "06/20/2023",
            value: "33",
            range: "<55",
            status: "Final",
          },
          listDetails: [
            "Last Encounter This Provider: 6/20/2023",
            "Last Encounter This Department: 6/20/2023",
            "Next Encounter This Provider: visit not found",
            "Next Encounter This Department: visit not found",
          ],
        },
      },
    ],
    dataReceived: {
      status: true,
      title:
        " 4/23/2024 - RX REQUEST: Mykelseyonline, Account and Epresciber Optum, MD",
      pendedOrder: "pended 1 order",
      datereceived: "04/23/2024",
      msg: "Med name dose route (doctor)",
      heading: "",
      popup: false,
    },
    recommendation: {
      status: false,
      title: "",
      heading: "",
      detail1: "",
      detail2: "",
      detail3: "",
      egfr: { date: "", value: "", range: "", status: "" },
      ast: { date: "", value: "", range: "", status: "" },
      clearance: { date: "", value: "", range: "", status: "" },
      egfrStatus: false,
      astStatus: false,
      clearanceStatus: false,
    },
    viewProtocalDetail: [
      {
        value: "Patient not pregnant nor breastfeeding",
        icon: "Tick",
      },
      {
        value: "Medication on active med list ",
        icon: "Tick",
      },
      {
        value: "Medication prescribed by PCP",
        icon: "Tick",
      },
      {
        value: "Request made ≤ 30 days from expiration",
        icon: "Cross",
      },
      {
        value: "Minimum of 30 days​",
        icon: "Tick",
      },
      {
        value: "No DUR identified",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in last 12 months",
        icon: "Tick",
      },
      {
        value: "Appointment with PCP in next 90 days",
        icon: "Cross",
      },
      {
        value:
          "Previous order was not a bridge refill per the automated solution",
        icon: "Tick",
      },
      {
        value: "Blood Pressure at goal within last 12 months",
        icon: "Tick",
      },
      {
        value: "HbA1c within last 9 months and within 5.7% - 8.5%",
        icon: "Tick",
      },
      {
        value: "Same medication and dose ≥ 3 months",
        icon: "Tick",
      },
      {
        value: "HR ≥ 55 bpm within last 12 months",
        icon: "Tick",
      },
      {
        value: "K+ / Na+ checked in last 12 months",
        icon: "Tick",
      },
      {
        value: "K+ / Na+ within normal limits",
        icon: "Cross",
      },
    ],
  },
];
export default middlePanelData;
