import React, { useState } from "react";
import { MailOutlineIcon } from "@uitk/react-icons";
import "../lastpanel.css";
import { ArrowForwardIcon } from "@uitk/react-icons";
import UserMessage from "./UserMesage";
import "./LastPanel.css";
import ModalCard from "../UIKit/ModalCard";
import RenderIcon from "./RenderIcons";
const LastPanel = ({ item, acceptButtonClicked }) => {
  const [showMessage, handleShowMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [cardItem, setetCardItem] = useState({});
  const handleToggleName = (checkValue) => {
    // console.log(checkValue)
    if (checkValue == true) {
      handleShowMessage(!showMessage);
    }
  };

  const usermsg = (msg) => {
    return (
      <>
        <p>Refills have been requested for the following medications:</p>
        <div>{msg}</div>
        <p>Preferred Pharmacy – KELSEY PHARMACY</p>
        <p> – MAIN –HOUSTON, TX – 27/27 W HOLCOME B1</p>
        <p>Medication renewals requested in this message routed separately.</p>
      </>
    );
  };

  const showCard = (item) => {
    setetCardItem(item);
    setOpen(true);
  };

  const showRenewals = (selectedPrescription, prescriptionNo) => {
    let show = true;
    if(acceptButtonClicked && !selectedPrescription) {
      show = false;
    }
    else if(selectedPrescription) {
      
          show = selectedPrescription[prescriptionNo] === true
       
    }
   
    return show;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div style={{ marginBottom: "10px", marginTop: "-10px" }}>
          <span className="message-tab">Message</span>
          <span className="message-left">&nbsp;</span>
        </div>
        <div className="row">
          <div className="col-2" style={{ padding: "0px" }}>
            <div className="circle">
              <span className="name">IP</span>
            </div>

            <div className="middlepanle-heading-customername">
              {item.patientName}
            </div>
            <div className="text">Legal Sex : Male</div>
            <div className="text">55 yrs old 9/28/1968</div>
            <div className="text">MRN: 100017134</div>
            <hr></hr>
            <div className="middlepanle-heading-customername">Phone</div>
            <div className="text">953-123-456(Mobilie)</div>
            <div className="text">872-345-678(Home Phone)952-555-1256</div>
            <hr></hr>
            <div className="text">last weight:test</div>
            <div className="text">Allergies:test</div>
            <div className="text">HM?: due</div>
            <div className="text">E-Consult Elig:No</div>
            <div className="text">PCP: Eprescriber Optum MD</div>
            <div className="text">Coverage:</div>
          </div>
          <div className="col-10" style={{ padding: "0px", overflow: "auto" }}>
            <div
              className={`card inactive`}
              style={{
                height: "auto",
                borderLeft: "5px solid RGB(85, 149, 229)",
              }}
            >
              <div>
                <span className="lastpanel-heading">
                  <MailOutlineIcon size="15" />
                  Medication Renewal Request
                </span>
                <span className="heading-right">Received: 1 month ago</span>
              </div>
              &nbsp;
              <div className="text">
                {" "}
                PatientName Name : {item.patientName}{" "}
                <ArrowForwardIcon size="12" /> {item.encProvider}{" "}
              </div>
              <div className="text"> Phone Number : 953-123-456</div>
              <div className="text">
                {" "}
                Refills have been requested for the medication:{" "}
              </div>
              <div className="text">{item.medicationRenewalRequest.detail}</div>
              {/* <div className="text"> Atenolol 25 MG oral tablet  (Eprescriber Optum, MD)</div>
            <div className="text"> Patient Comment. Please Refill this medication.</div>
            <div className="text"> Preferred pharmacy: KELSEY PHARMACY – testing – Houston, TX</div> */}
            </div>
            <div
              className={`card inactive`}
              style={{ height: "auto", borderLeft: "5px solid purple" }}
            >
              <div>
                <div>
                  <span className="lastpanel-heading-second">
                    <MailOutlineIcon size="15" />
                    Requested Renewals
                  </span>
                  <span className="heading-right">Received: 1 month ago</span>
                </div>

                {item.requestRenewals.map((itemChild, index) => {
                 
                 // if (acceptButtonClicked) {
                    if (!itemChild.pass || showRenewals(item?.selectedPrescription, itemChild.prescriptionNo)
                      ) {
                      return (
                        <div key={`renewal_${index}`}>
                          <div className="row">
                            <div className="col-s-12 col-m-2 col-l-2"></div>
                            <div className="col-s-12 col-m-8 col-l-8">
                              <div style={{ fontSize: 12, color: "blue" }}>
                                {itemChild.header}
                              </div>
                              <div className="text">{itemChild.detail1}</div>
                              <div className="text">{itemChild.detail2}</div>
                              <div className="text">{itemChild.detail3}</div>
                              <div className="text">{itemChild.detail4}</div>
                              <div
                                className="text"
                                style={{ marginBottom: "10px" }}
                              >
                                {itemChild.comments}
                              </div>
                            </div>
                          </div>
                          <hr style={{ width: "100%" }} />

                          <div className="row">
                            <div className="col-s-12 col-m-2 col-l-2"></div>

                            <div className="col-s-12 col-m-7 col-l-7">
                              <span
                                style={{
                                  color: itemChild.pass ? "green" : "red",
                                  fontSize: "13px",
                                  marginRight: "50px",
                                  fontWeight: "semibold",
                                }}
                              >
                                {itemChild.pass}
                                {itemChild.betaHeader}
                              </span>
                              <div className="text">
                                {itemChild.betaList.map((list, index) => (
                                  <div key={`banlist_${index}`}>
                                    {RenderIcon(list.icon)} {list.text}
                                  </div>
                                ))}
                              </div>
                            </div>

                           

                            <div className="col-s-12 col-m-3 col-l-3">
                              {itemChild.protocalDetail.status && (
                                <button
                                  style={{
                                    fontSize: "9px",
                                    background: "none",
                                    border: "none",
                                    color: "blue",
                                  }}
                                  onClick={() => showCard(itemChild)}
                                >
                                  Protocol Details
                                </button>
                              )}
                            </div>
                          </div>
                          <hr style={{ width: "100%" }} />
                        </div>
                      );
                    
                      }
                 
                })}
                {/* <!------------End-------------/> */}

                <div></div>
                <div style={{ borderBottom: "1px solid black" }} />
                <div className="text">
                  To be filled at: KELSEY PHARMACY – testing – Houston, TX
                </div>
              </div>
            </div>
            <div
              className={`card inactive`}
              style={{
                height: "auto",
                borderLeft: "5px solid RGB(85, 149, 229)",
              }}
              role="button"
              onClick={handleToggleName}
            >
              <div
                style={{
                  fontSize: "12px",
                  backgroundColor: "lightblue",
                  padding: "5px",
                }}
              >
                {item.dataReceived.title}
              </div>
              <div className="right-text">Newest Message First</div>
              <div className="center-text">
                {item.dataReceived.datereceived}
              </div>
              <hr />
              <div className="text" style={{ fontSize: "14px" }}>
                Mykelseyonline, Account pended an order
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4/22/2024
              </div>
              <hr />
              <div className="text">
                {" "}
                <span className="middlepanle-heading">
                  {item.patientName}{" "}
                </span>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eprescriber Optum MD{" "}
              </div>
              <div
                className="circle"
                role="button"
                onClick={() => handleToggleName(item.dataReceived.popup)}
              >
                <span className="name">HP</span>
              </div>
              <div>
                {showMessage && (
                  <UserMessage content={usermsg(item.dataReceived.msg)} />
                )}
              </div>
            </div>
            {/*  */}

            {item.recommendation.status ? (
              <div
                className={`card inactive`}
                style={{
                  height: "auto",
                  borderLeft: "5px solid RGB(85, 50, 229)",
                  paddingLeft: "10px",
                }}
              >
                <div style={{ fontSize: "13px", color: "#008AA6" }}>
                  <span>Inbox Assistant Recommendations</span>
                  <span>Protocol Review</span>
                </div>
                <div className="text">
                  One or more of the requested medications failed one of the in
                  basket assitant protocols
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    paddingTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {item.recommendation.heading}
                </div>
                <ul>
                  {item.recommendation.details.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
                <br />
                <div style={{ marginLeft: "20px" }}>
                  {item.recommendation.egfrStatus ? (
                    <>
                      <div className="table-title">
                        <b>eGFR</b>
                      </div>
                      <table className="table-width">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Value</th>
                            <th>Ref Range</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.recommendation.egfr.date}</td>
                            <td>{item.recommendation.egfr.value}</td>
                            <td>{item.recommendation.egfr.range}</td>
                            <td>{item.recommendation.egfr.status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  <br />
                  {item.recommendation.astStatus ? (
                    <>
                      {" "}
                      <div className="table-title">
                        <b>AST (SGOT)</b>
                      </div>
                      <table className="table-width">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Value</th>
                            <th>Ref Range</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.recommendation.ast.date}</td>
                            <td>{item.recommendation.ast.value}</td>
                            <td>{item.recommendation.ast.range}</td>
                            <td>{item.recommendation.ast.status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  <br />
                  {item.recommendation.clearanceStatus ? (
                    <>
                      <div className="table-title">
                        <b>Create Clearance</b>
                      </div>
                      <table className="table-width">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Value</th>
                            <th>Ref Range</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.recommendation.clearance.date}</td>
                            <td>{item.recommendation.clearance.value}</td>
                            <td>{item.recommendation.clearance.range}</td>
                            <td>{item.recommendation.clearance.status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
            {/*  */}
          </div>
        </div>
      </div>

      {open && (
        <ModalCard
          item={cardItem}
          setOpen={setOpen}
          open={open}
          patientId={item.id}
        />
      )}
    </div>
  );
};

export default LastPanel;
