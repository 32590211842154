import { React, useState } from "react";
import Accordian from "./Accordian";
import MiddlePanel from "./MiddlePanle";
import ToolbarContainer from "./Toolbar";
import LastPanel from "./LastPanel";

const items = [{ title: "Eprescriber Optum MD", content: "RX request" }];
// 

function HomeContainerScreen({ dataSource, acceptButtonClicked } ) {
  const [showMiddlePanel, setShowMiddlePanel] = useState(false);
  const [showLastPanel, setShowLastPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const callbackForSelectItem = () => {
    setShowMiddlePanel(true);
  };

  const callBackForLastPanel = (item, show) => {
    setSelectedRow(item);
    setShowLastPanel(show);
  };

  return (
    <div className="row">
      <div
        className="col-2"
        style={{
          border: "solid 1px gray",
          padding: "0px",
          backgroundColor: "White",
        }}
      >
        <div className="left-heading">
          <span>My Messages</span>
        </div>
        <div>
          <Accordian
            items={items}
            callbackForSelectItem={callbackForSelectItem}
            dataLength={dataSource.length}
          />
        </div>
      </div>

      <div className="col-10">
        <div className="row">
          <div className="col-12">
            <ToolbarContainer />
          </div>
        </div>
        <div className="row">
          <div
            className="col-5"
            style={{ border: "solid 1px gray", padding: "0px" }}
          >
            <MiddlePanel
              callBackForLastPanel={callBackForLastPanel}
              data={dataSource}
              acceptButtonClicked={acceptButtonClicked}
            />
          </div>
          {showLastPanel && (
            <div
              className="col-7"
              style={{ border: "solid 1px gray", padding: "0px" }}
            >
              <LastPanel item={selectedRow} acceptButtonClicked={acceptButtonClicked} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeContainerScreen;
