import React, { useState } from  'react'

const HARD_CODE_PASSWORD = "OPTUMINBOXASSISTANT" // we can change it as per our need

const LoginForm = ({handleLogin}) => {
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)


    const doLogin = () => {
        if(password === HARD_CODE_PASSWORD) {
            handleLogin(true)
            setError(false)
        } else {
             setError(true)
        }
    }
  


    return (
     <div className="container"> 
    
    { error && (
        <div style={{color: 'red'}}>Invalid Password</div> 
    )
    }
     <input className='login-input' type="password"
     placeholder="Enter Password" name="psw" required  onChange={(e) => setPassword(e.target.value)} />
          <button type='button' className='acceptbtn' onClick={doLogin}>
          Login
            </button>
        
     </div>
    )
}
export default LoginForm