import React, {useState} from 'react'
import { Toolkit } from "@uitk/react";
// import MenuScreen from './Menu'
import HomeContainerScreen from './HomeContainer'
import AssistantHeaderScreen from './AssistantHeader';
import LoginForm from './LoginForm';
import middlePanelData from "./MyContent";

function HomeScreen() {
    const [isLogin, setIsLogin] = useState(false)
    const [acceptButtonClicked, setAcceptButtonClicked] = useState(false)
    const [dataSource, setDataSource] = useState(middlePanelData);

  
    if(!isLogin) return <LoginForm handleLogin={setIsLogin} />
  
    return (
        <Toolkit grid>
            <div>                
                <AssistantHeaderScreen
                dataSource={dataSource}
                setDataSource={setDataSource}
                acceptButtonClicked={acceptButtonClicked}
                setAcceptButtonClicked={setAcceptButtonClicked}
                />                
                <HomeContainerScreen dataSource={dataSource} acceptButtonClicked={acceptButtonClicked} />
            </div>
        </Toolkit>
    )
}

export default HomeScreen