import React, { useState } from "react";
import { ExpandMoreIcon, ExpandLessIcon } from "@uitk/react-icons";
const Accordion = ({ items, callbackForSelectItem, dataLength }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleItem = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  const onSelectItem = (value) => {
    callbackForSelectItem(value)
  }
  return (
    <div>
      {" "}
      {items.map((item, index) => (
        <div key={index}>
          {" "}
          <div
            onClick={() => toggleItem(index)}
            style={{ cursor: "pointer", marginBottom: "5px", lineHeight: "15px" }}
          >
            
            <span>
            {expandedIndex === index ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )
            
          }
          </span>
          <span className="accordian-parent">{item.title}</span>
          </div>
          {expandedIndex === index && (
            <div className="accordian-children" role="button"
             onClick={() => {onSelectItem(item.content)}}>
            {item.content} <span style={{float: 'right'}}>0/{dataLength}</span>
            </div>
          )}{" "}
        </div>
      ))}{" "}
    </div>
  );
};
export default Accordion;
