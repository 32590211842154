import React, { useEffect, useState } from "react";

import './../middlepanel.css';



const MiddlePanel = ({callBackForLastPanel, data, acceptButtonClicked}) => {
    const [activeIndex, setActiveIndex] = useState(null)

    useEffect(() => {
      callBackForLastPanel(null, false)
    },  [acceptButtonClicked])
    const handleRowSelect = (item) => {
     
        setActiveIndex(item.id)
        callBackForLastPanel(item, true)
    }
  return (
    <>
   
    <div className="middlepanle-heading">
     <span style={{fontWeight: 'bold'}}>{`Attached & Covering Users > Eprescriber OptumMD`}</span>
    </div>
    { data.map((item) => (
    <div className="row" key={`row_${item.id}`}  role="button" onClick={() => handleRowSelect(item)}>

      <div className="column">
 
        <div className={`card ${item.patientName === activeIndex ? 'active' : 'inactive'}`}>
  
          <div style={{"float" : "left"}}>
      
            <p>Read:{item.datetime}</p>
            <p>Add’l Orders?:{item.orderID}</p>
            <p>Pool:{item.pool}</p>
            <p>Open?: {item.open}</p>
            <p>Comment:{item.comment}</p>
            <p>Inbox Assistant: {item.inbox}</p>
          </div>
          <div style={{"float" : "right"}}>
       
            <p>PatientName {item.patientName}</p>
            <p>Last Accessed:{item.lastAccessed}</p>
            <p>Sender: {item.sender}</p>
            <p>Enc Provider:{item.encProvider}</p>
            
          </div>
        </div>
      </div>
    </div>
  ))}
  </>
  );
};

export default MiddlePanel;
