import React from 'react';
import {
    CheckIcon,
    ReplyIcon,
    ReplyAllIcon,
    ForwardIcon,
    InsertChartIcon,
    EventNoteIcon,
    EditIcon,
    MessageIcon,
    CloseIcon,
    ArrowDropDownIcon
   }
from "@uitk/react-icons";

import { RenderToolBarIcon } from './RenderIcons'

const ToolbarContainer = () => {
    return (

      <div className="container middlepanle-toolbox">
      <div className="item">
       <div><RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon></div>
       <div>Done</div>
      </div>   
      <div className="item">
       <div><RenderToolBarIcon color="lavender" Component={ReplyIcon}></RenderToolBarIcon></div>
       <div>Reply</div>
      </div>
  
   <div className="item">
       <div><RenderToolBarIcon color="lavender"  Component={ReplyAllIcon}></RenderToolBarIcon></div>
       <div>Replyall</div>
      </div>
      <div className="item">
       <div><RenderToolBarIcon color="RGB(100,159,191)" Component={ForwardIcon}></RenderToolBarIcon></div>
       <div>Forward</div>
      </div>
      <div className="item" >
       <div><RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon></div>
       <div>Follow-up</div>
      </div>
      <div className="item" style={{borderLeft : '2px solid gray'}}>
        &nbsp;
      </div>

      <div className="item">
       <div><RenderToolBarIcon color="RGB(38,121,165)"  Component={InsertChartIcon}></RenderToolBarIcon></div>
       <div>Chart</div>
      </div>
      <div className="item">
       <div><RenderToolBarIcon color="green"  Component={CheckIcon}></RenderToolBarIcon></div>
       <div>Encounter</div>
      </div>
      <div className="item">
       <div><RenderToolBarIcon color="RGB(224,189,133)" Component={EventNoteIcon}></RenderToolBarIcon></div>
       <div>Note</div>
      
      </div>

      <div className="item" style={{borderLeft : '2px solid gray'}}>
        &nbsp;
      </div>
      <div className="item">
      <RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>Sign All</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>Sign and Route</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="red" Component={CloseIcon}></RenderToolBarIcon>
      
       <span style={{verticalAlign: 'top'}}>Refuse All</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="Dark green" Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>Refuse and Route</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="RGB(72,94,113)" Component={EditIcon}></RenderToolBarIcon>
      
       <span style={{verticalAlign: 'top'}}>Edit All</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="RGB(92,92,92)"  Component={MessageIcon}></RenderToolBarIcon>
     
       <span style={{verticalAlign: 'top'}}>Message Patient</span>
      </div>
      <div className="item" style={{borderLeft : '2px solid gray'}}>
        &nbsp;
      </div>
      <div className="item">
      <RenderToolBarIcon color="green"  Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>Appr need appt</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="green"  Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>2 Appr 0R</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="green"  Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>3 Appr 1R</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon>
       <span style={{verticalAlign: 'top'}}>4 Appr 3R</span>
      </div>
      <div className="item">
      <RenderToolBarIcon color="green" Component={ArrowDropDownIcon}></RenderToolBarIcon>
     
       <span style={{verticalAlign: 'top'}}>more</span>
      </div>
      
      
      
   </div>
  
   
  

   
  
    )
}

export default ToolbarContainer;