import React from "react";

import { 
  CheckIcon, 
  CloseIcon
} from "@uitk/react-icons";

export const RenderToolBarIcon = ({ Component, color, size=15}) => {
  return (
    <span
      style={{
        color,
      }}
    >
     <Component size={size} />
    </span>
  );
};

const RenderIcon = (iconText) => {
  switch (iconText) {
    case "Tick":
      return (
        <RenderToolBarIcon color="green" Component={CheckIcon}></RenderToolBarIcon>
      );
    case "Cross":
      return <RenderToolBarIcon color="red" Component={CloseIcon}></RenderToolBarIcon>
    default:
      return null;
  }
};

export default RenderIcon;
