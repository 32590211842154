import React from "react";
import { Card, Heading } from "@uitk/react";
import RenderIcon from "../Components/RenderIcons";

import "./ModalCard.css";

const ModalCard = ({ item, setOpen, patientId }) => {
 
  return (
   

    <Card
      className="cardModel"
      id={`ModalCard_${patientId}`}
      key={`ModalCard_${patientId}`}
      header={
        <Heading style={{ color: item.pass?"green": "red", }} level="h6">
          {item.protocalDetail.header}
        </Heading>
      }
    >
      <span
        style={{
          color: "red",
          fontSize: "12px",
          marginRight: "50px",
          fontWeight: "semibold",
        }}
      >
        {RenderIcon(item.protocalDetail.title?.icon)} {item.protocalDetail.title?.text}
      </span>
      {item.protocalDetail.listDetails.map((item, index)=>{
        return(
          <div key={`listdetails_${index}`}> {item} </div>
        )
      })}
     
      <br />
      {item.protocalDetail.tableStatus &&( <div style={{ marginLeft: "20px" }}>
        <div className="table-title" >
          <b>{RenderIcon(item.protocalDetail.tableHeader1?.icon)} {item.protocalDetail.tableHeader1?.text}</b>
        </div>
        <table className="table-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Value</th>
              <th>Ref Range</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {item.protocalDetail.creatinine.date}</td>
              <td> {item.protocalDetail.creatinine.value}</td>
              <td>{item.protocalDetail.creatinine.range}</td>
              <td> {item.protocalDetail.creatinine.status}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <div className="table-title" style={{color:"green"}}>
        <b>{RenderIcon(item.protocalDetail.tableHeader2?.icon)} {item.protocalDetail.tableHeader2?.text}</b>
        </div>
        <table className="table-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Value</th>
              <th>Ref Range</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {item.protocalDetail.lipid.date}</td>
              <td> {item.protocalDetail.lipid.value}</td>
              <td>{item.protocalDetail.lipid.range}</td>
              <td> {item.protocalDetail.lipid.status}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="table-title">
        <b>{RenderIcon(item.protocalDetail.tableHeader3?.icon)} {item.protocalDetail.tableHeader3?.text}</b>
        </div>
        <table className="table-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Value</th>
              <th>Ref Range</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{item.protocalDetail.ast.date}</td>
              <td>{item.protocalDetail.ast.value}</td>
              <td>{item.protocalDetail.ast.range}</td>
              <td>{item.protocalDetail.ast.status}</td>
            </tr>
          </tbody>
        </table>
      </div>)}
     
      <div>
        {" "}
        <button className="button-corner" onClick={() => setOpen(false)}>X</button>
      </div>
    </Card>
   
  );
};
export default ModalCard;
