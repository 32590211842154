import React, {useState} from 'react'
import { HomeIcon, PermContactCalendarIcon, InboxIcon } from "@uitk/react-icons";

import { Dialog } from "@uitk/react";
import DashboardScreen from "./Dashboard";



function AssistantHeaderScreen({dataSource, setDataSource, acceptButtonClicked, setAcceptButtonClicked }){
 
    const [open, setOpen] = useState(false)

    const handleInboxAssistant = () =>{
       
        setOpen(true)

    }
    const dismiss = () => setOpen(false);
   
    const handleAccept = () => {
      console.log(dataSource)
        const filteredData = [];
        const filteredDrowDownIds = ["4"] // here 4 means No Action
        dataSource.forEach((item) => {
            if(item.id === "2" || item.id === "4") {
              filteredData.push(item)
            } else {
              const filterPrescriptionList = item?.prescriptionList?.filter(val => filteredDrowDownIds.includes(val.dropdownId))
              if(filterPrescriptionList && filterPrescriptionList.length > 0) {
                filteredData.push(item)
              }
            }
          
        })

       if(filteredData && filteredData.length > 0) {
        setDataSource(filteredData)
       }
      
      
      setOpen(false);
      setAcceptButtonClicked(true)

    }
   
    return(
        <>
        <div className='row' style={{backgroundColor:'darkred', padding:'5px', alignItems:'center'}}>
           <span className="mr10"> <HomeIcon size="20" /> 
           <span className="navitem">Home</span></span>
           <span className="mr10"> <PermContactCalendarIcon size="20"  />
           <span className="navitem">Schedule</span></span>
           <span> <button type='button' className="inbox-ass-button" onClick={handleInboxAssistant}>
                Inbox Assistant</button></span>
            
        </div>

        <div className='row secondnav'>
           <span className="mr10"> <HomeIcon size="20" /> </span> 
           <span style={{backgroundColor : 'lightblue', height: '22px', width: '24px', textAlign : 'center'}}>
            <InboxIcon size="20" /></span>
        
            
        </div>
      {open && (
        <Dialog open={open} title="Optum Inbox Assistant Recommendations" titleAs="h6" onClose={dismiss}>
          
          <Dialog.Body>
         
         <DashboardScreen dataSource={acceptButtonClicked ? [] : dataSource} setDataSource={setDataSource} />
        
          </Dialog.Body>
        
            <div className='row'>
             <div className='col-12' style={{textAlign: 'right'}}>
            {/* <span className="pr10 discard" role='button'  onClick={dismiss}>Discard Changes</span> */}
            <span className="pr10">
     
            <button type='button' className='cancelbtn' onClick={dismiss}>
              Cancel
            </button>
            </span>

            <button type='button' className='acceptbtn' disabled={acceptButtonClicked}  onClick={handleAccept}>
              Accept
            </button>
            </div>
            </div>
         </Dialog>
      )}
        </>
    )
}

export default AssistantHeaderScreen